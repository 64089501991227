<div class="lp-content p-b-50">
    <mat-card class="outer-card">
        <mat-card-content class="container">
            <div class="row">
                <p class="m-0 fs-18 pb-2">
                    Potrzebujesz ubezpieczenia komunikacyjnego?
                </p>
                <h2 class="m-0 page-title pb-2">
                    <strong>Oblicz składkę już teraz</strong>
                </h2>
            </div>
            <div class="row justify-content-center">
                <form id="homePageForm"
                      name="homePageForm"
                      class="col-12"
                      (submit)="next(homePageForm)"
                      [ngClass]="homePageForm.submitted ? 'submitted' : ''"
                      #homePageForm="ngForm"
                      scrollToFirstInvalid>
                    <div class="row">
                        <div class="form-field custom-license-plate-container">
                            <svg width="44" height="64" viewBox="0 0 44 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 6C0 2.68629 2.68629 0 6 0H44V64H6C2.68629 64 0 61.3137 0 58V6Z" fill="#41479B"/>
                                <path d="M22.1086 11.0788L22.3777 11.8902L23.2278 11.8968C23.3379 11.8977 23.3835 12.039 23.295 12.1048L22.611 12.6129L22.8674 13.4284C22.9007 13.5339 22.7812 13.6213 22.6916 13.5569L22 13.0595L21.3083 13.5569C21.2188 13.6213 21.0993 13.534 21.1325 13.4284L21.3889 12.6129L20.7049 12.1048C20.6164 12.039 20.662 11.8977 20.7721 11.8968L21.6223 11.8902L21.8913 11.0788C21.926 10.9737 22.0738 10.9737 22.1086 11.0788Z" fill="#FFE15A"/>
                                <path d="M22.1086 28.4999L22.3777 29.3113L23.2278 29.318C23.3379 29.3188 23.3835 29.4601 23.295 29.5259L22.611 30.034L22.8674 30.8495C22.9007 30.955 22.7812 31.0425 22.6916 30.978L22 30.4806L21.3083 30.978C21.2188 31.0424 21.0993 30.9551 21.1325 30.8495L21.3889 30.034L20.7049 29.5259C20.6164 29.4601 20.662 29.3188 20.7721 29.318L21.6223 29.3113L21.8913 28.4999C21.926 28.3948 22.0738 28.3948 22.1086 28.4999Z" fill="#FFE15A"/>
                                <path d="M30.7668 19.7893L31.0359 20.6007L31.886 20.6073C31.9961 20.6082 32.0417 20.7495 31.9532 20.8153L31.2692 21.3234L31.5256 22.1389C31.5589 22.2444 31.4394 22.3318 31.3498 22.2674L30.6582 21.77L29.9665 22.2674C29.877 22.3318 29.7575 22.2444 29.7907 22.1389L30.0471 21.3234L29.3631 20.8153C29.2746 20.7495 29.3202 20.6082 29.4303 20.6073L30.2805 20.6007L30.5495 19.7893C30.5842 19.6843 30.732 19.6843 30.7668 19.7893Z" fill="#FFE15A"/>
                                <path d="M13.4504 19.7893L13.7195 20.6007L14.5696 20.6073C14.6797 20.6082 14.7253 20.7495 14.6368 20.8153L13.9528 21.3234L14.2092 22.1389C14.2425 22.2444 14.123 22.3318 14.0334 22.2674L13.3418 21.77L12.6501 22.2674C12.5606 22.3318 12.4411 22.2444 12.4743 22.1389L12.7307 21.3234L12.0467 20.8153C11.9582 20.7495 12.0038 20.6082 12.1139 20.6073L12.9641 20.6007L13.2331 19.7893C13.2679 19.6843 13.4157 19.6843 13.4504 19.7893Z" fill="#FFE15A"/>
                                <path d="M14.75 15.2406L15.019 16.052L15.8692 16.0586C15.9793 16.0595 16.0249 16.2007 15.9363 16.2666L15.2524 16.7747L15.5088 17.5902C15.542 17.6957 15.4225 17.7831 15.3329 17.7187L14.6413 17.2213L13.9497 17.7187C13.8602 17.783 13.7406 17.6957 13.7738 17.5902L14.0302 16.7747L13.3463 16.2666C13.2577 16.2007 13.3033 16.0595 13.4135 16.0586L14.2636 16.052L14.5326 15.2406C14.5674 15.1355 14.7151 15.1355 14.75 15.2406Z" fill="#FFE15A"/>
                                <path d="M29.7466 23.9511L30.0156 24.7625L30.8658 24.7692C30.9759 24.77 31.0215 24.9113 30.9329 24.9772L30.249 25.4853L30.5054 26.3007C30.5386 26.4064 30.4191 26.4937 30.3295 26.4292L29.6379 25.9318L28.9463 26.4292C28.8567 26.4936 28.7372 26.4063 28.7704 26.3007L29.0268 25.4853L28.3429 24.9772C28.2543 24.9113 28.2999 24.77 28.41 24.7692L29.2602 24.7625L29.5292 23.9511C29.564 23.8462 29.7118 23.8462 29.7466 23.9511Z" fill="#FFE15A"/>
                                <path d="M26.5775 12.0522L26.8465 12.8636L27.6966 12.8703C27.8068 12.8711 27.8524 13.0124 27.7638 13.0783L27.0799 13.5864L27.3363 14.4018C27.3695 14.5073 27.25 14.5948 27.1604 14.5304L26.4688 14.0329L25.7771 14.5304C25.6876 14.5947 25.568 14.5074 25.6012 14.4018L25.8576 13.5864L25.1737 13.0783C25.0851 13.0124 25.1307 12.8711 25.2409 12.8703L26.091 12.8636L26.36 12.0522C26.3948 11.9473 26.5425 11.9473 26.5775 12.0522Z" fill="#FFE15A"/>
                                <path d="M17.9192 27.1394L18.1882 27.9508L19.0384 27.9575C19.1485 27.9583 19.1941 28.0996 19.1055 28.1654L18.4216 28.6735L18.678 29.489C18.7112 29.5945 18.5917 29.682 18.5021 29.6175L17.8105 29.1201L17.1189 29.6175C17.0294 29.6819 16.9098 29.5946 16.943 29.489L17.1994 28.6735L16.5155 28.1654C16.4269 28.0996 16.4725 27.9583 16.5827 27.9575L17.4328 27.9509L17.7018 27.1395C17.7366 27.0343 17.8844 27.0343 17.9192 27.1394Z" fill="#FFE15A"/>
                                <path d="M29.5292 15.2406L29.2602 16.052L28.41 16.0586C28.2999 16.0595 28.2543 16.2007 28.3429 16.2666L29.0268 16.7747L28.7704 17.5902C28.7372 17.6957 28.8567 17.7831 28.9463 17.7187L29.6379 17.2213L30.3295 17.7187C30.4191 17.783 30.5386 17.6957 30.5054 17.5902L30.249 16.7747L30.9329 16.2666C31.0215 16.2007 30.9759 16.0595 30.8658 16.0586L30.0156 16.052L29.7466 15.2406C29.7118 15.1355 29.564 15.1355 29.5292 15.2406Z" fill="#FFE15A"/>
                                <path d="M14.5326 23.9511L14.2636 24.7625L13.4135 24.7692C13.3033 24.77 13.2577 24.9113 13.3463 24.9772L14.0302 25.4853L13.7738 26.3007C13.7406 26.4064 13.8601 26.4937 13.9497 26.4292L14.6413 25.9318L15.3329 26.4292C15.4225 26.4936 15.542 26.4063 15.5088 26.3007L15.2524 25.4853L15.9363 24.9772C16.0249 24.9113 15.9793 24.77 15.8692 24.7692L15.019 24.7625L14.75 23.9511C14.7151 23.8462 14.5674 23.8462 14.5326 23.9511Z" fill="#FFE15A"/>
                                <path d="M17.7018 12.0522L17.4328 12.8636L16.5827 12.8703C16.4725 12.8711 16.4269 13.0124 16.5155 13.0783L17.1994 13.5864L16.943 14.4018C16.9098 14.5073 17.0293 14.5948 17.1189 14.5304L17.8105 14.0329L18.5021 14.5304C18.5917 14.5947 18.7112 14.5074 18.678 14.4018L18.4216 13.5864L19.1055 13.0783C19.1941 13.0124 19.1485 12.8711 19.0384 12.8703L18.1882 12.8636L17.9192 12.0522C17.8843 11.9473 17.7366 11.9473 17.7018 12.0522Z" fill="#FFE15A"/>
                                <path d="M26.36 27.1394L26.091 27.9508L25.2409 27.9575C25.1307 27.9583 25.0851 28.0996 25.1737 28.1654L25.8576 28.6735L25.6012 29.489C25.568 29.5945 25.6875 29.682 25.7771 29.6175L26.4687 29.1201L27.1603 29.6175C27.2499 29.6819 27.3694 29.5946 27.3362 29.489L27.0798 28.6735L27.7637 28.1654C27.8523 28.0996 27.8067 27.9583 27.6966 27.9575L26.8464 27.9509L26.5774 27.1395C26.5425 27.0343 26.3948 27.0343 26.36 27.1394Z" fill="#FFE15A"/>
                                <path d="M21.724 42.504C21.724 40.68 20.548 39.72 18.1 39.72H15.268V48H16.972V45.396H18.076C20.44 45.396 21.724 44.316 21.724 42.504ZM19.996 42.552C19.996 43.452 19.396 44.016 18.076 44.016H16.972V41.16H18.076C19.396 41.16 19.996 41.664 19.996 42.552ZM25.0368 39.72H23.3328V48H29.0208V46.512H25.0368V39.72Z" fill="white"/>
                            </svg>
                            <app-license-plate-number [modelRef]="cepikRequestModel"
                                                      [showTooltip]="true"></app-license-plate-number>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-field custom-birth-date-container">
                            <app-datepicker-materials id="birthDate"
                                                      [modelRef]="cepikRequestModel"
                                                      [modelRefField]="'birthDate'"
                                                      [startAt]="defaultBirthDate"
                                                      [minDate]="minBirthDate"
                                                      [maxDate]="maxBirthDate"
                                                      [required]="true"
                                                      [matLabelValue]="'Data urodzenia właściciela'"
                                                      [customClass]="'mat-form-field mat-primary mat-form-field-type-mat-input landing-page-datepicker'">
                            </app-datepicker-materials>
                        </div>
                    </div>
                    <div class="row mb-3 mt-1" *ngIf="consent">
                        <mat-checkbox id="consent"
                                      name="lp-consent"
                                      [(ngModel)]="consent.selected"
                                      #consentElementRef="ngModel"
                                      [required]="consent.required">
                            <app-consent-content [consent]="consent"></app-consent-content>
                        </mat-checkbox>
                    </div>
                    <div class="row">
                        <button mat-button class="next-button w-100">
                            OBLICZ SKŁADKĘ
                        </button>
                    </div>
                    <div *ngIf="homePageForm.invalid && homePageForm.submitted"
                         class="mt-4 container row invalid-submitted-form-text justify-content-center">
                        Wypełnij wymagane pola!
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="outer-card m-t-30">
        <mat-card-content class="container p-0">
            <p class="text text-small-medium">
                Jeśli nie pamiętasz numeru rejestracyjnego lub&nbsp;nie&nbsp;posiadasz jeszcze auta<br />
                <a id="cepik-declaration-path"
                   class="declaration-path-button"
                   gtm-emit-click="wprowadz-dane-recznie"
                   (click)="navigateToDeclarationPath()">
                    Wprowadź dane ręcznie
                </a>
            </p>
            <app-documents [showPrivacyPolicy]="true"></app-documents>
        </mat-card-content>
    </mat-card>
</div>
<div class="row p-t-50 lp-wall-desc">
    <div class="col-12">
        <div class="row">
            <div class="col-lg-10 col-sm-12 offset-lg-1 text text-center">
                <h1>Ubezpieczenia komunikacyjne - wybierz najlepszą ofertę z LINK4</h1>
                <p>Ubezpieczenia komunikacyjne to temat dotyczący wszystkich właścicieli pojazdów mechanicznych. Każdego roku polisa OC musi zostać odnowiona, aby można było poruszać się pojazdem po drogach - zwykle dzieje się to automatycznie. Ubezpieczenia komunikacyjne mają za zadanie chronić Cię przed finansowymi konsekwencjami zdarzeń drogowych, w których brałeś udział. Kalkulator OC dostępny na tej stronie daje Ci  możliwość wyboru najlepszej oferty ubezpieczenia!</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 offset-lg-1 col-sm-12">
                <h2 class="m-t-40">Kalkulator OC - szybko, prosto i wygodnie</h2>
                <p class="text text-small-medium align-justify">Ubezpieczenie OC to obowiązkowa polisa, którą musi wykupić każdy właściciel pojazdu mechanicznego. Dotyczy to zatem samochodów osobowych, ciężarowych, ale również motocykli czy skuterów. Polisa odpowiedzialności cywilnej ważna jest zazwyczaj przez 12 miesięcy. Umowa ubezpieczenia gwarantuje, że jeśli będziesz sprawcą wypadku lub stłuczki, odszkodowanie za wyrządzone szkody wypłaci Twój ubezpieczyciel.</p>
                <p class="text text-small-medium align-justify">Ubezpieczenie OC należy wykupić nie tylko ze względu na to, że jest ono obowiązkowe. Dzięki niemu jesteś finansowo zabezpieczony w przypadku nieprzewidzianej sytuacji drogowej. Wielu kierowców, gdy przychodzi czas wykupienia polisy obawia się wysokiej składki. A gdyby dało się to zrobić taniej, a w dodatku szybko i bez wychodzenia z domu? Jest to możliwe! Odpowiednie narzędzie znajdziesz w LINK4!</p>
                <p class="text text-small-medium align-justify">Dostęp do kalkulatora OC/AC to możliwość szybkiego i łatwego obliczenia składki za ubezpieczenie komunikacyjne. Wystarczy podać kilka podstawowych informacji. Do obliczenia składki potrzebna jest m.in. Twoja data urodzenia, rok produkcji pojazdu czy jego przebieg. Wyliczenie OC to tylko kilka minut, i w dodatku bez wychodzenia z domu, bo kalkulator jest dostępny online.  Kalkulator ubezpieczeń OC w LINK4 to rozwiązanie, dzięki któremu życie staje się prostsze!</p>

                <h2 class="m-t-40">Kalkulator OC w LINK4 - jak się przygotować?</h2>
                <p class="text text-small-medium align-justify">Kalkulator OC to narzędzie, dzięki któremu zakup ubezpieczenia komunikacyjnego jest niezwykle komfortowy. Obliczanie wysokości składki przebiega podobnie jak w przypadku wizyty w oddziale ubezpieczyciela. Musisz się do tego odpowiednio przygotować i mieć najważniejsze dokumenty. Dzięki temu wyszukiwanie i obliczanie nowego OC przebiegnie sprawnie i zrobisz to w zaledwie kilka minut.</p>
                <p class="text text-small-medium align-justify">Jakich dokumentów potrzebuję? Co muszę mieć pod ręką? Zanim uruchomisz kalkulator OC, przygotuj dowód rejestracyjny. Znajdziesz tam m.in. numer VIN czy datę pierwszej rejestracji. Przypomnij sobie również swój numer PESEL oraz rok uzyskania prawo jazdy. Obliczanie wysokości ubezpieczenia w LINK4 to czysta przyjemność! Nasz Kalkulator OC to minimum formalności przy  maksimum satysfakcji!</p>

                <h2 class="m-t-40">Kalkulator OC samochodu - jak działa?</h2>
                <p class="text text-small-medium align-justify">Kalkulator OC w LINK4 jest niezwykle prosty w użytkowaniu. Intuicyjna platforma gwarantuje, że z pewnością się w niej odnajdziesz i bez problemu przejdziesz przez wszystkie etapy. Aby sprawdzić swoją składkę, musisz tylko przygotować niezbędne dokumenty, o których pisaliśmy wcześniej.</p>
                <p class="text text-small-medium align-justify">Kalkulator OC online od LINK4 to wygodne narzędzie. Aby wyliczyć OC, możesz wybrać jedną z trzech ścieżek: podając PESEL, skanując dowód rejestracyjny (gdy korzystasz z wersji mobilnej kalkulatora) lub wpisując dane ręcznie. Jeśli wybrałeś ten ostatni, wówczas musisz przejść przez 5-etapowy proces. Ale nie martw się - jeśli przygotowałeś wszystkie niezbędne dokumenty, potrwa to dosłownie moment.</p>
                <p class="text text-small-medium align-justify">Gdy już uruchomisz kalkulator OC, najpierw podaj informacje dotyczące Twojego samochodu: rok produkcji, markę, model i wersję wyposażenia. W tym momencie wybierz zakres - czy interesuje Cię OC, czy OC/AC? W zależności od wybranej wersji, sposób wyliczania składki będzie się różnić.</p>
                <p class="text text-small-medium align-justify">Kolejny etap to informacje dotyczące użytkowania auta. Kalkulator OC i AC zawiera więcej pytań niż w przypadku samego ubezpieczenia od odpowiedzialności cywilnej. Na tym etapie musisz podać informacje dotyczące umiejscowienia kierownicy, aktualny stan licznika czy sposób użytkowania auta.</p>
                <p class="text text-small-medium align-justify">Trzecia podstrona zawiera formularz dotyczący kierowców oraz właścicieli  pojazdu. Podaj ich liczbę oraz wybierz, który z nich jest głównym użytkownikiem danego pojazdu. A jeśli jednym z kierowców jest osoba, która nie ukończyła 24. roku życia? Zaznacz odpowiednią odpowiedź w formularzu i podaj jej wiek. W następnym kroku wprowadź dane właściciela pojazdu: imię i nazwisko, data urodzenia, płeć, rok uzyskania prawa jazdy czy liczba lat ubezpieczenia.</p>
                <p class="text text-small-medium align-justify">Przedostatni etap zawiera niezbędne zgody. W tym miejscu podaj również datę od której ma rozpocząć się nowe ubezpieczenie. Jeśli posiadasz, to możesz również podać kod promocyjny lub wpisać numer Karty Dużej Rodziny, dzięki którym uzyskasz atrakcyjny rabat. Następnie zaakceptuj niezbędne zgody i potwierdź informacje, które podałeś we wcześniejszych formularzach.</p>
                <p class="text text-small-medium align-justify">To koniec. Cztery krótkie formularze i kalkulator OC ma dla Ciebie przygotowaną ofertę. Co zrobić, żeby kupić polisę? Szybka płatność przez Internet i gotowe. Czy można wyobrazić sobie lepszy kalkulator OC i AC? Sprawdź naszą ofertę i oblicz wysokość składki samodzielnie!</p>

                <h2 class="m-t-40">Dlaczego warto skorzystać z kalkulatora OC?</h2>
                <p class="text text-small-medium align-justify">Kalkulator OC/AC w LINK4 to jeden z wielu elementów naszej naprawdę bogatej oferty. Dzięki niemu możesz w prosty sposób zorientować się w aktualnych cenach ubezpieczeń komunikacyjnych, ale również wykupić niezbędną polisę. Dlaczego warto wybrać akurat kalkulator OC w LINK4? Jest kilka dobrych powodów!</p>
                <p class="text text-small-medium align-justify">Kalkulator ubezpieczenia samochodu w LINK4 to przede wszystkim łatwe, intuicyjne oraz przejrzyste narzędzie, dzięki któremu szybko znajdziesz interesującą Cię ofertę. Składa się z 5 etapów, co sprawia, że wypełniając formularz krok po kroku nie pominiesz żadnego fragmentu. Kalkulator ubezpieczenia OC zawiera również pytania z gotowymi odpowiedziami do wyboru. To istotnie przyspiesza proces.</p>
                <p class="text text-small-medium align-justify">Do innych zalet kalkulatora OC od LINK4 należy możliwość zakupu ubezpieczenia dla różnych rodzajów pojazdów. Możesz wybrać auto osobowe, jak i dostawcze czy osobowo-dostawcze. W LINK4 możesz również sprecyzować, do jakich celów używasz dany pojazd.</p>
                <p class="text text-small-medium align-justify">Ile trwa obliczenie składki OC? Zapewniamy, nie musisz brać dnia wolnego w pracy czy rezygnować z innych obowiązków. Obliczenie ubezpieczenia wymaga od Ciebie zaledwie kilku minut. Możesz to zrobić w przerwie w pracy, na studiach czy jadąc autobusem. Kalkulator OC to narzędzie, dzięki któremu życie staje się prostsze!</p>

                <h2 class="m-t-40">Dlaczego warto polegać na kalkulatorze OC? Ubezpieczenie komunikacyjne bez wychodzenia z domu</h2>
                <p class="text text-small-medium align-justify">W przypadku kalkulatora OC wszystko znajduje się w jednym miejscu. Wystarczy komputer lub telefon i połączenie internetowe. Narzędzie służy nie tylko do znalezienia atrakcyjnej oferty polisy odpowiedzialności cywilnej. Dzięki niemu możesz wykupić różnego rodzaju ubezpieczenia oraz opcje dodatkowe, które dopasujesz do swoich wymagań.</p>
                <p class="text text-small-medium align-justify">Ubezpieczenie Wracaj do Zdrowia, Szyby24 czy NNW to tylko kilka propozycji, które na Ciebie czekają. Dzięki nim zabezpieczysz się na wypadek nieprzewidzianych sytuacji na drodze czy  postoju na parkingu. Rozważ również ubezpieczenie „Pomoc po kradzieży”. W razie utraty pojazdu, będzie przysługiwać Ci odszkodowanie w wysokości 2000 zł, które przeznaczysz na wybrany przez siebie cel, np. na pokrycie kosztów związanych z wynajęciem samochodu zastępczego lub taksówki. Sprawdź, co jeszcze znajdziesz w naszym kalkulatorze i przekonaj się, że jest to świetny sposób na zakup ubezpieczenia!</p>
            </div>
            <div class="col-lg-5 col-sm-12">
                <h2 class="m-t-40">Wybór ubezpieczenia komunikacyjnego - wybierz mądrze w LINK4!</h2>
                <p class="text text-small-medium align-justify">Ubezpieczenia komunikacyjne to złożone zagadnienie. Wielu kierowców wybierając polisę, kieruje się ceną składki. Co wpływa na jej wysokość? W LINK4 bierzemy pod uwagę czynniki związane zarówno z kierowcą, jak i pojazdem, który ma być objęty ubezpieczeniem. Bardzo duże znaczenie ma wiek właściciela, jego doświadczenie za kierownicą, ale również lata bezszkodowej jazdy. Nie bez znaczenia jest również miejsce zamieszkania. Jeśli chodzi o pojazd mechaniczny, to najważniejsze są rok produkcji, marka, model czy pojemność silnika.</p>
                <p class="text text-small-medium align-justify">Kalkulator OC w LINK4 to możliwość dopasowania odpowiedniej oferty do swoich potrzeb. Dzięki niemu dowiesz się, jaką składkę będziesz musiał zapłacić. W LINK4 dbamy o przejrzystość działań - a w tej relacji bardzo ważne jest wzajemne zaufanie.</p>

                <h2 class="m-t-40">Autocasco w LINK4 - oblicz swoją składkę!</h2>
                <p class="text text-small-medium align-justify">Autocasco to jedno z dobrowolnych ubezpieczeń. Choć jego posiadanie nie jest obowiązkowe, kierowcy bardzo często je kupują. AC to ochrona pojazdu w razie jego uszkodzenia, np. w wyniku stłuczki, lub w przypadku jego kradzieży. Dzięki temu jesteś zabezpieczony, ponieważ otrzymane odszkodowanie będziesz mógł przeznaczyć na naprawę uszkodzeń lub zakup innego pojazdu. Autocasco chroni również przed szkodami wyrządzonymi przez osoby trzecie, np. w przypadku aktu wandalizmu.</p>
                <p class="text text-small-medium align-justify">Wybierając polisę AC, upewnij się, że pakiet zawiera wszystko, czego potrzebujesz. W LINK4 znajdziesz kompleksowy zestaw ochrony - gwarantuje Ci on ubezpieczenie Twojego pojazdu na wypadek różnych, nieprzewidzianych zdarzeń. Wszystko to w przystępnej cenie. Sprawdź, ile będzie kosztować ubezpieczenie AC Twojego pojazdu. Otwórz nasz kalkulator i oblicz swoją składkę!</p>

                <h2 class="m-t-40">Tanie ubezpieczenie samochodu? Kalkulator OC w LINK4 - wybierz dla siebie odpowiednią ofertę</h2>
                <p class="text text-small-medium align-justify">Wysokość składki ubezpieczenia OC może czasem przyprawić o zawrót głowy. Dlatego wybierz mądrze! Kalkulator, który znajdziesz na naszej stronie to nie tylko sposób na łatwe, komfortowe i szybkie znalezienie odpowiedniej polisy. To także możliwość skorzystania z różnego rodzaju zniżek, rabatów i promocji.</p>
                <p class="text text-small-medium align-justify">W LINK4 możesz liczyć na atrakcyjne zniżki. Posiadasz Kartę Dużej Rodziny? Złożyłeś wniosek o ubezpieczenie samochodu online? Płacisz kartą przez telefon? W każdej z tych sytuacji możesz liczyć na obniżkę składki. W LINK4 cyklicznie organizujemy również różne rabaty i kupony zniżkowe. Zapisz się do naszego newslettera i bądź na bieżąco!</p>

                <h2 class="m-t-40">Kończy Ci się ubezpieczenie? Znajdź nową polisę w kalkulatorze OC!</h2>
                <p class="text text-small-medium align-justify">Zgodnie z polskim prawem każdy pojazd mechaniczny musi posiadać ważne ubezpieczenie odpowiedzialności cywilnej. Nawet jednodniowa przerwa w ciągłości polisy skutkuje karą finansową. Ale brak ważnego ubezpieczenia może skutkować nie tylko karą ze strony UFG. Jeśli spowodujesz wypadek pojazdem, który nie ma polisy OC, za wyrządzoną szkodę będziesz musiał zapłacić z własnej kieszeni.</p>
                <p class="text text-small-medium align-justify">Kalkulator OC i AC w LINK4 to sposób, by szybko kupić nowe ubezpieczenie. Dzięki temu nie będziesz musiał się martwić ewentualnymi konsekwencjami. Obliczanie ubezpieczenia to zaledwie kilka minut. Kalkulator OC uwzględni wszystkie parametry i wybierze dla Ciebie odpowiednią ofertę.</p>
                <p class="text text-small-medium align-justify">Planujesz zmianę swojego dotychczasowego ubezpieczenia? Zbliża się koniec ważności aktualnego OC? Kalkulacja w LINK4 naprawdę Ci się opłaca! Atrakcyjne zniżki, różnego rodzaju dodatkowe ubezpieczenia - to wszystko gwarantuje kalkulator OC dostępny na naszej stronie internetowej!</p>

                <h2 class="m-t-40">Dlaczego warto wybrać ubezpieczenie w LINK4?</h2>
                <p class="text text-small-medium align-justify">Najlepsze i najtańsze OC - czy te dwie cechy można połączyć? Oczywiście, że tak. Poszukiwania odpowiedniej oferty mogą jednak być naprawdę skomplikowane. Jak zatem wybrać dla siebie odpowiednią polisę komunikacyjną?</p>
                <p class="text text-small-medium align-justify">Przede wszystkim określ, czego dokładnie potrzebujesz. W LINK4 znajdziesz kompleksową ochronę w różnego rodzaju sytuacjach. Kalkulator OC i AC to gwarancja znalezienia naprawdę dobrej oferty i zabezpieczenia w każdej sytuacji.</p>
                <p class="text text-small-medium align-justify">LINK4 to również gwarancja sprawnej obsługi - od wyboru ubezpieczenia do likwidacji szkody i obsługi posprzedażowej. Kalkulator OC to narzędzie, dzięki któremu wybierzesz i wykupisz polisę dopasowaną do Twoich potrzeb.</p>
                <p class="text text-small-medium align-justify">W przypadku ubezpieczeń komunikacyjnych liczy się także szybkość działania. W LINK4 wiemy, że każda minuta jest na wagę złota, dlatego robimy wszystko, żeby likwidacja szkody oraz rozwiązywanie bieżących problemów przebiegały maksymalnie sprawnie. W takich sytuacjach skontaktuj się z nami korzystając z naszej specjalnej infolinii oraz formularzy kontaktowych online.</p>
                <p class="text text-small-medium align-justify">Wyboru ubezpieczenia OC nie ułatwia ilość towarzystw, które funkcjonują na rynku. Warto jednak zaufać liderom w tej branży. LINK4 jest obecne na rynku ubezpieczeń od wielu lat. Coraz więcej klientów decyduje się przejść do nas i wykupić polisę komunikacyjną. Szybkie i łatwe zawarcie umowy to niewątpliwie zaleta, która przekonuje nawet niezdecydowanych. Dzięki temu nie musisz spędzać długich godzin nad analizowaniem różnych ofert!</p>

                <h2 class="m-t-40">Kupuj w pakiecie - OC i AC się opłaca!</h2>
                <p class="text text-small-medium align-justify">Kierowcy coraz częściej interesują się dobrowolnymi ubezpieczeniami bo potrzebują większej ochrony, niż ta, która jest gwarantowana przy samym OC. Pakiet OC i AC to również sposób na tańsze ubezpieczenie komunikacyjne, które naprawdę się opłaca. Kalkulator OC i AC to narzędzie, dzięki któremu znajdziesz dla siebie odpowiednią ofertę. Wybierając odpowiedni pakiet, będziesz mógł być spokojny o siebie, swoich bliskich oraz pojazd. Ubezpieczenie w LINK4 to gwarancja ochrony w wielu problematycznych sytuacjach. Więcej szczegółów na ten temat znajdziesz w Ogólnych Warunkach Ubezpieczenia. Wejdź na naszą stronę i sprawdź pełną ofertę!</p>

                <h2 class="m-t-40">Oblicz składkę OC na kalkulatorze w LINK4!</h2>
                <p class="text text-small-medium align-justify">Obliczanie OC to zadanie z pozoru skomplikowane. Towarzystwa ubezpieczeniowe biorą pod uwagę wiele różnych czynników np. wiek kierowcy, jego doświadczenie, ale również rodzaj pojazdu i jego parametry. Nie musisz mieć skomplikowanego narzędzia oraz sprzętu, by samodzielnie znaleźć i wykupić najlepszą polisę. Kalkulator OC i AC w LINK4 jest na wyciągnięcie ręki.</p>
                <p class="text text-small-medium align-justify">Na kalkulatorze OC ubezpieczenie wykupisz szybko, bez wychodzenia z domu lub w jakimkolwiek innym miejscu, w którym się obecnie znajdujesz. Obliczenie składki OC to zaledwie kilka minut. Kalkulator polisy OC jest całkowicie darmowy. Pamiętaj, aby podać prawdziwe informacje. Dzięki temu system wybierze dla Ciebie odpowiednią ofertę! Sprawdź nasz kalkulator OC i przekonaj się, że ubezpieczenie wcale nie musi być takie drogie! Oblicz OC samodzielnie!</p>
                <p class="text text-small-medium align-justify">Ubezpieczenie Autocasco dostępne w LINK4 tylko w pakiecie z OC. Szczegółowe informacje o produktach, limitach i wyłączeniach sprawdź w OWU dostępnym na <a href="https://www.link4.pl">www.link4.pl</a></p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 offset-lg-1 col-sm-12">
                <h2 class="m-t-40">Czym jest Kalkulator OC i pakietu OC+AC?</h2>
                <p class="text text-small-medium align-justify">Kalkulator OC to narzędzie, które pozwala obliczyć koszt ubezpieczenia komunikacyjnego bez wychodzenia z domu. Po podaniu podstawowych danych na temat pojazdu otrzymasz kalkulację polisy w kilku wariantach. Sam wybierasz, czy chcesz poznać cenę ubezpieczenia odpowiedzialności cywilnej, czy pakietu OC i AC. W obu przypadkach otrzymasz szczegółowe informacje na temat wysokości składki oraz zakresu ochrony.</p>

                <h2 class="m-t-40">Jak działa Kalkulator OC i pakietu OC+AC?</h2>
                <p class="text text-small-medium align-justify">Kalkulator OC i pakietu OC+AC wylicza wysokość składki na ubezpieczenie komunikacyjne na podstawie danych przedstawionych przez zainteresowanego ofertą. Narzędzie umożliwia weryfikację ceny polisy w kilka minut, bez wychodzenia z domu. Za każdym razem otrzymasz kilka wersji ubezpieczenia z dokładnym wskazaniem jego zakresu.</p>

                <h2 class="m-t-40">Jak obliczyć ubezpieczenie samochodu w Kalkulatorze OC i pakietu OC+AC?</h2>
                <p class="text text-small-medium align-justify">Kalkulator ubezpieczenia samochodu jest dostępny online. Aby uzyskać wyliczenie, musisz podać podstawowe dane, takie jak m.in. numer rejestracyjny pojazdu, datę urodzenia i rok uzyskania prawa jazdy przez właściciela, a także sposób użytkowania pojazdu. System poprosi Cię również o informację, czy którykolwiek z właścicieli lub użytkownik samochodu ma mniej niż 24 lata, a także o termin, od kiedy potrzebujesz ubezpieczenia. W tym miejscu możesz też zostawić swoje dane kontaktowe. Na podstawie wpisanych informacji kalkulator wskaże dostępne oferty, ich ceny oraz szczegółowy zakres.</p>

                <h2 class="m-t-40">Dla jakich pojazdów Kalkulator OC i pakietu OC+AC obliczy składkę?</h2>
                <p class="text text-small-medium align-justify">Kalkulator dostępny na stronie internetowej LINK4 obliczy składkę ubezpieczenia OC lub pakietu OC i AC dla pojazdów różnych marek – zarówno nowych, jak i starszych modeli. Wystarczy, że podasz numer rejestracyjny samochodu, aby otrzymać spersonalizowane informacje na temat dostępnych opcji polisy.</p>

                <h2 class="m-t-40">Jakie dane są potrzebne do obliczenia składki w Kalkulatorze OC i pakietu OC+AC?</h2>
                <p class="text text-small-medium align-justify">Obliczenie składki polisy komunikacyjnej w Kalkulatorze OC wymaga podania kilku danych. Przede wszystkim danych pojazdu, danych kierowcy, a także sposobu użytkowania pojazdu oraz daty rozpoczęcia ochrony. Na tej podstawie narzędzie wyliczy ofertę ubezpieczenia.</p>

                <h2 class="m-t-40">Czy muszę podawać swoje dane w Kalkulatorze OC i pakietu OC+AC?</h2>
                <p class="text text-small-medium align-justify">Podanie podstawowych danych na temat pojazdu i jego kierowcy jest niezbędne do obliczenia składki ubezpieczenia komunikacyjnego. Chodzi jednak o proste informacje, takie jak np. data urodzenia. Nie musisz podawać swoich danych kontaktowych. Nawet bez ich udostępnienia otrzymasz kalkulację. W takim wypadku nie będziemy jednak mogli się z Tobą skontaktować. Zachęcamy wobec tego do podawania podstawowych danych kontaktowych!</p>
            </div>
            <div class="col-lg-5 col-sm-12">
                <h2 class="m-t-40">Czy korzystanie z Kalkulatora OC i pakietu OC+AC jest płatne?</h2>
                <p class="text text-small-medium align-justify">Kalkulator OC i AC to narzędzie w pełni bezpłatne, z którego może skorzystać każdy zainteresowany. Podając podstawowe dane, otrzymasz atrakcyjne warianty ubezpieczenia i dokonasz porównania dostępnych opcji. Aby przeprowadzić wyliczenie, wystarczy dostęp do internetu i komputer, tablet lub smartfon.</p>

                <h2 class="m-t-40">Czy moje dane w Kalkulatorze OC i pakietu OC+AC są bezpieczne?</h2>
                <p class="text text-small-medium align-justify">Dane, które podajesz w celu wyliczenia składki OC lub AC w kalkulatorze, są w pełni bezpieczne. Stosowane zabezpieczenia sprawiają, że nie musisz obawiać się o uzyskanie do nich dostępu przez osoby niepożądane. W celu przeprowadzenia wyliczenia nie wymagamy również podania danych dokumentów, takich jak numer prawa jazdy czy dowodu osobistego. Wystarczające są podstawowe informacje na temat pojazdu i jego właściciela.</p>

                <h2 class="m-t-40">Dlaczego warto kupić OC i AC za pomocą Kalkulatora online?</h2>
                <p class="text text-small-medium align-justify">Kalkulator OC+AC pozwala wyliczyć koszt polisy bez wychodzenia z domu. Jeśli decydujesz się na oba produkty, otrzymasz atrakcyjną cenę. Kalkulator ubezpieczenia samochodu umożliwia weryfikację kosztu i zakresu polisy w kilka chwil. Dzięki temu bez trudu zweryfikujesz, który wariant umowy sprawdzi się najlepiej w Twoim przypadku.</p>

                <h2 class="m-t-40">Jestem współwłaścicielem pojazdu – czy mogę wyliczyć składkę OC za pomocą kalkulatora OC i pakietu OC+AC?</h2>
                <p class="text text-small-medium align-justify">Jeśli jesteś współwłaścicielem pojazdu, bez przeszkód możesz wyliczyć cenę OC lub pakietu OC i AC oraz porównać dostępne warianty polisy. W tym przypadku – aby wskazana cena była wiarygodna – musisz uwzględnić dane pozostałych współwłaścicieli, które znajdują się w dowodzie rejestracyjnym. Po wpisaniu wymaganych informacji do kalkulatora otrzymasz kalkulację wysokości składki.</p>

                <h2 class="m-t-40">Czy po wyliczeniu składki za pomocą kalkulatora muszę dokonać zakupu?</h2>
                <p class="text text-small-medium align-justify">Wyliczenie składki ubezpieczenia komunikacyjnego jest dobrowolne. Uzupełnij swoje dane kontaktowe i dokonaj zakupu. Możesz skorzystać z kalkulatora OC i pakietu OC+AC. Masz również możliwość weryfikacji zakresu polisy w LINK4, nawet jeśli masz jeszcze ważne ubezpieczenie w innym towarzystwie ubezpieczeniowym.</p>
            </div>
        </div>
    </div>
</div>