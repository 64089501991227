import {ProductModel} from "@app/shared/services/gtm/model/product.model";
import {InsuranceType} from "@app/calculate/vehicle/vehicle.model";
import {CoverModel, InsuranceVariantModel} from "@app/calculate/calculate/models/insurance-variant.model";
import {ProductTagBuilderType} from "@app/shared/services/gtm/model/product-tag-builder-type.model";
import {CalculationResponseModel} from "@app/calculate/calculate/models/calculation-response.model";
import {InsuranceTotalPriceModel} from "@app/shared/models/insurance-total-price/insurance-total-price.model";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";

export class ProductMapper {

  private static readonly BRAND = 'Link4';
  private static readonly OFFER_LIST_OC = 'Lista ofert OC';
  private static readonly OFFER_LIST_OC_AC = 'Lista ofert OC/AC';
  private static readonly ADDITIONAL_COVERS = 'Opcje dodatkowe';
  private static readonly PAYMENT = 'Płatność';

  public static map(calculationStoreService: CalculationStoreService,
                    calculationResponse: CalculationResponseModel,
                    productTagBuilderType: ProductTagBuilderType,
                    insuranceTotalPrice?: InsuranceTotalPriceModel,
                    additionalSelectedCovers?: CoverModel[]): ProductModel[] {
    if (calculationResponse && calculationResponse.variants) {
      return calculationResponse.variants
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(insuranceVariant => ProductMapper.variantFilter(insuranceVariant, productTagBuilderType))
        .map((insuranceVariant, i) => {
          const product = new ProductModel();
          const variant = ProductMapper.getVariants(insuranceVariant, insuranceTotalPrice, productTagBuilderType, additionalSelectedCovers);
          const list = ProductMapper.getList(calculationResponse.insuranceType, productTagBuilderType);
          const position = ProductMapper.getPosition(i, productTagBuilderType);
          const quantity = ProductMapper.getQuantity(productTagBuilderType);
          const price = ProductMapper.getPrice(productTagBuilderType, insuranceVariant, calculationStoreService);
          const variantId = insuranceVariant.packageName.toLowerCase().replace(' ', '_');
          const calculationId = calculationStoreService.getCalculationId();

          product.id = `${variantId}_${calculationId}`;
          product.name = insuranceVariant.name;
          product.price = price;
          product.brand = ProductMapper.BRAND;
          product.category = calculationResponse.insuranceType;

          if (variant) {
            product.variant = variant;
          }

          if (list) {
            product.list = list;
          }

          if (position) {
            product.position = position;
          }

          if (quantity) {
            product.quantity = quantity;
          }

          return product;
        });
    }

    return undefined;
  }

  private static getList(insuranceType: InsuranceType,
                         productTagBuilderType: ProductTagBuilderType): string {
    if (ProductTagBuilderType.IMPRESSION === productTagBuilderType) {
      if (InsuranceType.OC === insuranceType) return ProductMapper.OFFER_LIST_OC;
      if (InsuranceType.OC_AND_AC === insuranceType) return ProductMapper.OFFER_LIST_OC_AC;
    }

    return undefined;
  }

  private static variantFilter(insuranceVariant: InsuranceVariantModel,
                               productTagBuilderType: ProductTagBuilderType): boolean {
    if (ProductTagBuilderType.IMPRESSION === productTagBuilderType) return true;

    return insuranceVariant.selected === true;
  }

  private static getVariants(insuranceVariant: InsuranceVariantModel,
                             insuranceTotalPrice: InsuranceTotalPriceModel,
                             productTagBuilderType: ProductTagBuilderType,
                             additionalSelectedCovers?: CoverModel[]): string {
    let basicCovers: string;
    let additionalCovers = 'Nie wybrano';
    let payment = 'Nie wybrano';

    if (insuranceVariant && insuranceVariant.covers) {
      basicCovers = insuranceVariant.covers.map(cover => cover.name).join(', ');
    }

    if (insuranceTotalPrice && insuranceTotalPrice.installmentCount) {
      payment = insuranceTotalPrice.installmentCount.translation;
    }

    if (additionalSelectedCovers && additionalSelectedCovers.length) {
      additionalCovers = additionalSelectedCovers.map(additionalCover => additionalCover.name).join(', ');
    }

    return `${insuranceVariant.name}: ${basicCovers}; ` +
           `${ProductMapper.ADDITIONAL_COVERS}: ${additionalCovers}; ` +
           `${ProductMapper.PAYMENT}: ${payment}`;
  }

  private static getPosition(index: number, productTagBuilderType: ProductTagBuilderType): number {
    if (ProductTagBuilderType.IMPRESSION === productTagBuilderType) {
      return index + 1;
    }

    return undefined;
  }

  private static getQuantity(productTagBuilderType: ProductTagBuilderType): number {
    if (ProductTagBuilderType.CHECKOUT_STEP_1 === productTagBuilderType ||
        ProductTagBuilderType.CHECKOUT_STEP_2 === productTagBuilderType ||
        ProductTagBuilderType.CHECKOUT_STEP_3 === productTagBuilderType ||
        ProductTagBuilderType.PURCHASE === productTagBuilderType) {
      return 1;
    }

    return undefined;
  }

  private static getPrice(productTagBuilderType: ProductTagBuilderType,
                          insuranceVariant: InsuranceVariantModel,
                          calculationStoreService: CalculationStoreService): string {
    const additionalInsuranceVariant = calculationStoreService.getAdditionalInsuranceVariant();

    if (ProductTagBuilderType.IMPRESSION === productTagBuilderType ||
        ProductTagBuilderType.CLICK === productTagBuilderType ||
        ProductTagBuilderType.DETAIL === productTagBuilderType) {
      return insuranceVariant.price.toString();
    }

    if (additionalInsuranceVariant?.installment) {
      return (additionalInsuranceVariant.installment.count * additionalInsuranceVariant.installment.price).toString();
    }

    return undefined;
  }

}
