<header>
    <div class="d-flex">
        <svg width="160" height="40" viewBox="0 0 160 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3632_18316)">
                <path d="M30.5483 0H0V40H30.5483V0Z" fill="#501E82"/>
                <path d="M15.053 26.4958V9.11572H10.7109V30.8843H23.3039V26.4958H15.053Z" fill="white"/>
                <path d="M63.0068 0H32.4585V40H63.0068V0Z" fill="#781E82"/>
                <path d="M49.9012 9.11572H45.5591V30.8796H49.9012V9.11572Z" fill="white"/>
                <path d="M95.4653 0H64.917V40H95.4653V0Z" fill="#A50F82"/>
                <path d="M84.7358 9.11572V23.6501L76.0097 9.11572H71.3042V30.8843H75.6509V16.35L84.3863 30.8843H89.0778V9.11572H84.7358Z" fill="white"/>
                <path d="M127.924 0H97.3755V40H127.924V0Z" fill="#EB007D"/>
                <path d="M121.839 9.11572H117.026L111.422 17.8081H108.603V9.11572H104.256V30.8843H108.603V22.1919H111.422L117.026 30.8843H121.839L114.813 20L121.839 9.11572Z" fill="white"/>
                <path d="M148.768 23.1985V9.11572H144.006L134.954 23.1985V27.5823H144.426V30.8796H148.768V27.5823H151.428V23.1985H148.768ZM144.426 23.1985H139.669L144.426 15.7291V23.1985Z" fill="#6E6E6E"/>
                <path d="M160.005 0H158.477V40H160.005V0Z" fill="#6E6E6E"/>
            </g>
            <defs>
                <clipPath id="clip0_3632_18316">
                    <rect width="160" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>
        <h1 class="pl-3">
            <strong>Kalkulator OC</strong>&nbsp;i Pakietu OC/AC
        </h1>
    </div>
    <div class="d-flex" *ngIf="!FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK">
        <div class="pr-2">
            <app-livechat type="header"></app-livechat>
        </div>
        <div class="callback-header">
            <callback></callback>
        </div>
    </div>
</header>
