import {Injectable} from '@angular/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {NavigationEnd, Router} from '@angular/router';
import {VirtualPageViewTagBuilder} from '@app/shared/services/gtm/tag/virtual-page-view-tag.builder';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {BreadcrumbService} from '@app/shared/components/breadcrumb/breadcrumb.service';
import {ProductTagBuilder} from '@app/shared/services/gtm/tag/product-tag.builder';
import {ProductTagBuilderType} from '@app/shared/services/gtm/model/product-tag-builder-type.model';
import {CallbackTagBuilder} from '@app/shared/services/gtm/tag/callback-tag.builder';
import {CallbackCategory} from '@app/shared/services/gtm/model/callback-category.model';
import {CallbackAction} from '@app/shared/services/gtm/model/callback-action.model';
import {ScannerCategory} from '@app/shared/services/gtm/model/scanner-category.model';
import {ScannerAction} from '@app/shared/services/gtm/model/scanner-action.model';
import {ScannerTagBuilder} from '@app/shared/services/gtm/tag/scanner-tag.builder';
import {ScannerLabel} from '@app/shared/services/gtm/model/scanner-label.model';
import {ConfigService} from '@app/shared/services/config/config.service';
import {StepOrderResolver} from '@app/shared/services/gtm/mapper/step-order.resolver';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {ConfigModel} from '@app/shared/services/config/config.model';
import {StepResolver} from '@app/shared/services/url/step-resolver';
import {ProductCheckoutStep} from '@app/shared/services/gtm/model/product-checkout-step.model';
import {ItCategory} from '@app/shared/services/gtm/model/it-category.model';
import {ItAction} from '@app/shared/services/gtm/model/it-action.model';
import {ItTagBuilder} from '@app/shared/services/gtm/tag/it-tag.builder';
import {EntryLocationTagBuilder} from '@app/shared/services/gtm/tag/entry-location-tag.builder';
import {PersonalDataStoreService} from '@app/shared/services/personal-data/personal-data-store.service';
import {PersonalDataType} from '@app/shared/services/personal-data/personal-data-store.model';
import {UrlParamsStoreService} from "@app/shared/interceptors/partner/url-params-store.service";
import {GeneratorService} from "@app/shared/services/generator/generator.service";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {FormFieldClickTagBuilder} from "@app/shared/services/gtm/tag/form-field-click-tag.builder";
import {FormFieldErrorTagBuilder} from "@app/shared/services/gtm/tag/form-field-error-tag.builder";

@Injectable()
export class GtmService {

  private readonly config: ConfigModel;
  private readonly storeService: CalculationStoreService;
  private breadcrumbService: BreadcrumbService;
  private pushTagQueue: Array<any> = [];
  private pushTagWaiting: boolean = false;

  constructor(private router: Router,
              private gtmService: GoogleTagManagerService,
              private configService: ConfigService,
              private personalDataStoreService: PersonalDataStoreService,
              private urlParamsStoreService: UrlParamsStoreService,
              private generatorService: GeneratorService,
              private featureFlagService: FeatureFlagService) {
    this.config = this.configService.getConfigInstant();
    this.storeService = new CalculationStoreService(urlParamsStoreService, generatorService);
    this.breadcrumbService = new BreadcrumbService(this.storeService, this.featureFlagService);
  }

  public emitEntryLocationEvent() {
    const location = document.location.href;

    const tag = EntryLocationTagBuilder.builder()
      .entryLocation(location)
      .build();

    this.pushTag(tag);
  }

  public emitVirtualPageViewEvent(navigation: NavigationEnd) {
    if (StepResolver.isSessionExpiredStep(navigation.url))  {
      return;
    }

    const stepOrder = StepOrderResolver.resolveFromNavigation(navigation, this.storeService);
    const virtualPageViewTagBuilder = VirtualPageViewTagBuilder.builder()
      .page(window.location)
      .eCommPageType(navigation)
      .eCommFunnelType(this.storeService.getLandingPage())
      .calculationID(this.storeService.getCalculationId())
      .partnerID(this.urlParamsStoreService.getPartnerId())
      .stepDisplayNo(this.breadcrumbService.getStep(navigation))
      .stepOrder(navigation, this.storeService)
      .pageTitle(navigation);

    if (stepOrder > StepOrderResolver.resolveFromStep(Step.VEHICLE, this.storeService)) {
      virtualPageViewTagBuilder.vehicle(this.storeService.getVehicle());
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(Step.VEHICLE_ADDITIONAL_INFO, this.storeService)) {
      virtualPageViewTagBuilder.vehicleAdditionalInfo(this.storeService.getVehicleAdditionalInfo());
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(Step.VEHICLE_OWNER, this.storeService)) {
      virtualPageViewTagBuilder.vehicleOwner(this.storeService.getVehicleOwner());
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(this.getMainVehicleOwnerDetailsStep(), this.storeService)) {
      virtualPageViewTagBuilder.vehicleMainUserDetails(this.storeService.getMainVehicleUserDetails());
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(Step.VEHICLE_OWNER_DETAILS, this.storeService)) {
      virtualPageViewTagBuilder.vehicleOwnerDetails(this.personalDataStoreService.getPersonalData(PersonalDataType.OWNER));
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(Step.VEHICLE_FIRST_CO_OWNER_DETAILS, this.storeService)) {
      virtualPageViewTagBuilder.vehicleFirstCoOwnerDetails(this.personalDataStoreService.getPersonalData(PersonalDataType.FIRST_CO_OWNER));
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(Step.VEHICLE_SECOND_CO_OWNER_DETAILS, this.storeService)) {
      virtualPageViewTagBuilder.vehicleSecondCoOwnerDetails(this.personalDataStoreService.getPersonalData(PersonalDataType.SECOND_CO_OWNER));
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(Step.INSURANCE_START_DATE, this.storeService)) {
      virtualPageViewTagBuilder.insuranceStartDate(this.storeService.getInsuranceStartDate());
    }
    if (stepOrder > StepOrderResolver.resolveFromStep(OfferStep.CONTACT_AND_CONSENT, this.storeService)) {
      virtualPageViewTagBuilder.contactAndConsent(this.storeService.getContactAndConsent());
    }

    const tag = virtualPageViewTagBuilder.build();

    this.pushTag(tag);
  }

  public emitProductImpressionEvent() {
    const tag = ProductTagBuilder.builder(ProductTagBuilderType.IMPRESSION)
      .calculationID(this.storeService.getCalculationId())
      .partnerID(this.urlParamsStoreService.getPartnerId())
      .eCommerce(this.storeService)
      .build();

    this.pushTag(tag);
  }

  public emitProductClickEvent() {
    const tag = ProductTagBuilder.builder(ProductTagBuilderType.CLICK)
      .calculationID(this.storeService.getCalculationId())
      .partnerID(this.urlParamsStoreService.getPartnerId())
      .eCommerce(this.storeService)
      .build();

    this.pushTag(tag);
  }

  public emitProductDetailEvent() {
    const tag = ProductTagBuilder.builder(ProductTagBuilderType.DETAIL)
      .calculationID(this.storeService.getCalculationId())
      .eCommerce(this.storeService)
      .build();

    this.pushTag(tag);
  }

  public emitProductCheckoutEvent(productCheckoutStep: ProductCheckoutStep) {
    const tag = ProductTagBuilder.builder(this.getCheckoutProductTagBuilder(productCheckoutStep))
      .calculationID(this.storeService.getCalculationId())
      .partnerID(this.urlParamsStoreService.getPartnerId())
      .eCommerce(this.storeService)
      .build();

    this.pushTag(tag);
  }

  public emitProductPurchaseEvent() {
    const tag = ProductTagBuilder.builder(ProductTagBuilderType.PURCHASE)
      .calculationID(this.storeService.getCalculationId())
      .partnerID(this.urlParamsStoreService.getPartnerId())
      .eCommFunnelType(this.storeService.getLandingPage())
      .eCommerce(this.storeService)
      .build();

    this.pushTag(tag);
  }

  public emitCallbackEvent(action: CallbackAction, label?: string) {
    const tag = CallbackTagBuilder.builder()
      .calculationID(this.storeService.getCalculationId())
      .partnerID(this.urlParamsStoreService.getPartnerId())
      .category(CallbackCategory.EVENTS)
      .action(action)
      .label(label)
      .value(0)
      .nointeract(0)
      .build();

    this.pushTag(tag);
  }

  public emitItEvent(label: string) {
    const tag = ItTagBuilder.builder()
      .category(ItCategory.EVENTS)
      .action(ItAction.ERROR_POPUP)
      .label(label)
      .build();

    this.pushTag(tag);
  }

  public emitFormFieldClick(fieldName: string) {
    const tag = FormFieldClickTagBuilder.builder()
        .formFieldName(fieldName)
        .build();

    this.pushTag(tag);
  }

  public emitFormFieldError(fieldName: string) {
    const tag = FormFieldErrorTagBuilder.builder()
        .formFieldName(fieldName)
        .build();

    this.pushTag(tag);
  }

  private pushTag(tag?: any) {
    if (tag) {
      this.pushTagQueue.push(tag);
    }

    if (!this.pushTagWaiting && this.pushTagQueue.length) {
      this.pushTagWaiting = true;
      this.gtmService.pushTag(this.normalizeTag(this.pushTagQueue[0])).then(
        (value) => {
          this.pushTagQueue.shift();
          this.pushTagWaiting = false;
        },
        (reason) => {
          this.pushTagWaiting = false;
        }
      );
    } else {
      setTimeout(() => this.pushTag(), 1000);
    }
  }

  private normalizeTag(tag: any): any {
    return JSON.parse(
      JSON.stringify(tag, (k, v) => {
        return (v === undefined) ? '__undefined' : v;
      }).replace('__undefined', 'undefined')
    );
  }

  private getCheckoutProductTagBuilder(productCheckoutStep: ProductCheckoutStep): ProductTagBuilderType {
    if (productCheckoutStep === ProductCheckoutStep.STEP_3) { return ProductTagBuilderType.CHECKOUT_STEP_3; }
    if (productCheckoutStep === ProductCheckoutStep.STEP_2) { return ProductTagBuilderType.CHECKOUT_STEP_2; }
    return ProductTagBuilderType.CHECKOUT_STEP_1;
  }

  private getMainVehicleOwnerDetailsStep(): Step {
    const vehicleOwner = this.storeService.getVehicleOwner();
    if (!vehicleOwner) {
      return undefined;
    }

    switch (vehicleOwner.mainUser) {
      case PersonalDataType.OWNER: return Step.VEHICLE_OWNER_DETAILS;
      case PersonalDataType.FIRST_CO_OWNER: return Step.VEHICLE_FIRST_CO_OWNER_DETAILS;
      case PersonalDataType.SECOND_CO_OWNER: return Step.VEHICLE_SECOND_CO_OWNER_DETAILS;
    }
  }

}
