import { ProductTagModel } from "@app/shared/services/gtm/tag/product-tag.model";
import { ProductECommerceMapper } from "@app/shared/services/gtm/mapper/product-ecommerce.mapper";
import { ProductTagBuilderType } from "@app/shared/services/gtm/model/product-tag-builder-type.model";
import { CalculationStoreService } from "@app/calculate/calculate/calculation-store.service";
import {LandingPageModel} from "@app/landing-page/landing-page.model";
import {ECommFunnelTypeMapper} from "@app/shared/services/gtm/mapper/ecomm-funnel-type.mapper";

export class ProductTagBuilder {

  private readonly productTagBuilderType: ProductTagBuilderType;
  private readonly tag: ProductTagModel;

  constructor(productTagBuilderType: ProductTagBuilderType) {
    this.productTagBuilderType = productTagBuilderType;
    this.tag = new ProductTagModel();

    switch (productTagBuilderType) {
      case ProductTagBuilderType.IMPRESSION: this.tag.event = 'productImpression'; break;
      case ProductTagBuilderType.CLICK: this.tag.event = 'productClick'; break;
      case ProductTagBuilderType.DETAIL: this.tag.event = 'productDetail'; break;
      case ProductTagBuilderType.CHECKOUT_STEP_1: this.tag.event = 'checkout'; break;
      case ProductTagBuilderType.CHECKOUT_STEP_2: this.tag.event = 'checkout'; break;
      case ProductTagBuilderType.CHECKOUT_STEP_3: this.tag.event = 'checkout'; break;
      case ProductTagBuilderType.PURCHASE: this.tag.event = 'purchase'; break;
    }
  }

  public static builder(productTagBuilderType: ProductTagBuilderType): ProductTagBuilder {
    return new ProductTagBuilder(productTagBuilderType);
  }

  public calculationID(calculationID: string): ProductTagBuilder {
    this.tag.calculationID = calculationID;
    return this;
  }

  public partnerID(partnerID: string): ProductTagBuilder {
    this.tag.partnerID = partnerID ? partnerID : '695';
    return this;
  }

  public eCommFunnelType(landingPage: LandingPageModel): ProductTagBuilder {
    const inputMode = landingPage ? landingPage.inputMode : undefined;
    this.tag.ecommFunnelType = ECommFunnelTypeMapper.map(inputMode);
    return this;
  }

  public eCommerce(calculationStoreService: CalculationStoreService): ProductTagBuilder {
    this.tag.ecommerce = ProductECommerceMapper.map(calculationStoreService, this.productTagBuilderType);
    return this;
  }

  public build(): ProductTagModel {
    return this.tag;
  }

}
