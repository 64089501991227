import { InputMode } from "@app/landing-page/landing-page.model";

export class ECommFunnelTypeMapper {

  private static readonly MANUAL_FILLING = 'Manual Filling';
  private static readonly CEPIK = 'cepik';
  private static readonly UFG = 'ufg';
  private static readonly OMNI = 'omni';
  private static readonly NOT_CHOSEN = '(not_chosen)';

  public static map(inputMode: InputMode): string {
    switch (inputMode) {
      case InputMode.DECLARATION:
        return ECommFunnelTypeMapper.MANUAL_FILLING;
      case InputMode.UFG:
        return ECommFunnelTypeMapper.UFG;
      case InputMode.CEPIK:
        return ECommFunnelTypeMapper.CEPIK;
      case InputMode.OMNI:
        return ECommFunnelTypeMapper.OMNI;
      default:
        return ECommFunnelTypeMapper.NOT_CHOSEN;
    }
  }

}
